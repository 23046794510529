<template>
<div id="app" class="">
  <nav-bar v-if="showNav"></nav-bar>
  <router-view :key="$route.fullPath"/>
</div>
</template>


<script>
import navBar from '@/components/navBar'
import {initSession} from '@/config/session-manager.js'



export default {
  name: "App",
  components: {
    navBar
  },
  data() {
    return {
      
    }
  },
  computed: {
      showNav() {
        return this.$state.showNav
      },
      company () {
        return this.$state.userInfo.company
      },
      
  },
  methods: {
    
  },
  created() {
    if(this.$isMobile()){
      this.$state.isMobile = true
    }
  },
  mounted () {
    //1. Check if we have a token, if no redirect to /login
    console.log(`mounted called initing session....`)
    
      initSession()
    
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&family=Roboto:wght@400;700&display=swap');
body {
  font-family: 'Roboto'!important;
}
.monoTitle {
  font-family: 'Roboto Mono'!important;
}
</style>